<template>
  <CompraDividaList />
</template>

<script>
import CompraDividaList from '@/components/shared/compraDivida/CompraDividaList.vue'

export default {
  components: {
    CompraDividaList,
  },
}
</script>
