export default class CompraDividaService {
  constructor(http) {
    this._http = http
  }

  async getListaCompraDividasVendidasPorMatriculaEConsignatariaSelecionada(
    matricudaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/compra-divida/servidor/matricula/vendidas/${matricudaId}/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaCompraDividasCompradasPorMatriculaEConsignatariaSelecionada(
    matricudaId,
    consignatariaId,
  ) {
    const { data } = await this._http.get(
      `/api/compra-divida/servidor/matricula/compradas/${matricudaId}/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getComprasDividasPorConsignatariaCompradoPendente(consignatariaId) {
    const { data } = await this._http.get(
      `/api/compra-divida/servidor/matricula/compradas/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getComprasDividasPorConsignatariaVendidoPendente(consignatariaId) {
    const { data } = await this._http.get(
      `/api/compra-divida/servidor/matricula/vendido/consignataria/${consignatariaId}`,
    )
    return data
  }

  async getListaCompraDividasPorMatricula(matricudaId) {
    const { data } = await this._http.get(
      `/api/compra-divida/matricula/${matricudaId}`,
    )
    return data
  }

  async getCompraDividasUsuarioLogado() {
    const { data } = await this._http.get(`/api/compra-divida/servidor`)
    return data
  }

  async getListaConsignacoesPorProposta(propostaId) {
    const { data } = await this._http.get(
      `/api/compra-divida/proposta/${propostaId}`,
    )
    return data
  }

  async getCompraDivida(id) {
    const { data } = await this._http.get(`/api/compra-divida/${id}`)
    return data
  }

  async findByPropostaConsignacaoId(id) {
    const { data } = await this._http.get(`/api/compra-divida/propostaid/${id}`)
    return data
  }

  async save(compraDivida) {
    if (compraDivida) {
      return await this._http.post('/api/compra-divida', compraDivida)
    }
  }

  async aprovarProposta(compraDivida, consignatariaId) {
    if (compraDivida) {
      return await this._http.patch(
        `/api/compra-divida/aprovarCompraDivida/consignataria/${consignatariaId}`,
        compraDivida,
      )
    }
  }
}
