<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :loading="loading"
            :value="compraDividas"
            dataKey="id"
            :filters.sync="filtros"
            :globalFilterFields="[
              'proposta.rubrica.rubrica',
              'proposta.mesReferencia',
              'proposta.anoReferencia',
              'proposta.numeroContrato',
              'proposta.statusConsignacao.nome',
            ]"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} compras de dívidas"
            responsiveLayout="stack">
            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2 mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filtros['global'].value"
                    placeholder="Pesquisar"
                    style="width: 100%" />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Limpar"
                  class="p-button-outlined mb-2"
                  @click="limparFiltro" />
              </div>
            </template>
            <template #empty> Nenhuma portabilidade encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column field="mesReferencia" header="Mês/Ano" class="col-4">
              <template #body="{ data }">
                {{
                  typeof data.proposta !== 'undefined'
                    ? ('0' + data.proposta.mesReferencia).slice(-2)
                    : ''
                }}/{{
                  typeof data.proposta !== 'undefined'
                    ? data.proposta.anoReferencia
                    : ''
                }}
              </template>
            </Column>
            <Column :sortable="true" field="rubrica" header="Rubrica">
              <template #body="{ data }">
                {{
                  typeof data.proposta !== 'undefined'
                    ? data.proposta.rubrica.rubrica
                    : ''
                }}
                -
                {{
                  typeof data.proposta !== 'undefined'
                    ? data.proposta.rubrica.nome
                    : ''
                }}
              </template>
            </Column>
            <Column
              :sortable="true"
              field="proposta.contrato"
              header="Contrato" />
            <Column field="proposta.prazoTotal" header="Prazo" />
            <Column header="Valor da Parcela">
              <template #body="{ data }">
                <div class="flex justify-content-end flex-wrap card-container">
                  <div class="flex align-items-center justify-content-center">
                    {{ data.proposta.valorParcela | formatarValor }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              :sortable="true"
              field="proposta.statusProposta.nome"
              header="Situação">
              <template #body="{ data }">
                <Chip
                  v-if="data.proposta.statusProposta.nome === 'Aprovada'"
                  label="Aprovada"
                  class="mr-2 mb-2 custom-chip ativa" />
                <Chip
                  v-else-if="data.proposta.statusProposta.nome === 'Cancelada'"
                  label="Cancelada"
                  class="mr-2 mb-2 custom-chip cancelada" />
                <Chip
                  v-else-if="data.proposta.statusProposta.nome === 'Reprovada'"
                  label="Reprovada"
                  class="mr-2 mb-2 custom-chip suspensa" />
                <Chip
                  v-else
                  label="Pendente"
                  class="mr-2 mb-2 custom-chip pendente" />
              </template>
            </Column>
            <Column header="Cadastrado Em">
              <template #body="{ data }">
                {{ data.proposta.auditMetadata.cadastradoEm | formatarData }}
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  v-tooltip.left="'Detalhes'"
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-success"
                  @click="exibirDetalheProposta(data)" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import CompraDividaService from '@/service/CompraDividaService'

export default {
  data() {
    return {
      filtros: {},
      compraDividas: [{}],
      compraDivida: null,
      loading: false,
      idMatricula: this.$route.params.id,
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.service = new CompraDividaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarComprasDividas()
  },

  methods: {
    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    carregarComprasDividas() {
      this.loading = true
      this.service.getListaCompraDividasPorMatricula(this.idMatricula).then(
        (res) => {
          this.compraDividas = res
          this.loading = false
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    exibirDetalheProposta(data) {
      this.$router.push({
        name: 'compra-divida-detalhe',
        params: { idcompradivida: data.id },
      })
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },

    limpar() {
      this.initFiltros()
      this.compraDivida = null
      this.carregarComprasDividas()
    },
  },
}
</script>

<style scoped>
.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}
.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}
.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
}
.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}
</style>
